export const stats_shorts = [
  "full_pm",
  "wide_pm",
  "BL",
  "MF",
  "FFC",
  "SR",
  "BS",
  "LBX",
  "TV32",
  "TV40",
  "DFT",
  "FT",
  "BB",
  "DSK",
  "HBW",
  "HBL",
];

export const shorts = [
  "PM",
  "BL",
  "MF",
  "FFC",
  "SR",
  "BS",
  "LBX",
  "TV32",
  "TV40",
  "DFT",
  "FT",
  "BB",
  "DSK",
  "HBW",
  "HBL",
];

export const payscale_shorts = [
  "PM",
  "BL",
  "MF",
  "FFC",
  "SR",
  "BS",
  "LBX",
  "TV",
  "FT",
  "HBL",
  "HBW",
];

export const short_shorts = [
  "PM",
  "BL",
  "MF",
  "FFC",
  "SR",
  "BS",
  "LBX",
  "TV",
  "FT",
  "DSK",
  "BB",
  "HB",
  "HBL",
  "HBW",
];

export const money_shorts = [
  "PM",
  "BL",
  "MF",
  "FFC",
  "SR",
  "BS",
  "LBX",
  "TV",
  "DSK",
  "BB",
  "FT",
  "HBL",
  "HBW",
];

export const stat_short_order = [
  "full_pm",
  "wide_pm",
  "BL",
  "SR",
  "BS",
  "MF",
  "FFC",
  "FT",
  "DFT",
  "LBX",
  "TV32",
  "TV40",
  "DSK",
  "BB",
  "HB",
  "HBL",
  "HBW",
];
export const short_order = [
  "PM",
  "BL",
  "SR",
  "BS",
  "MF",
  "FFC",
  "FT",
  "DFT",
  "LBX",
  "TV32",
  "TV40",
  "DSK",
  "BB",
  "HB",
  "HBW",
  "HBL",
];

type StringMap = {
  [key: string]: string;
};

const shortShortMap: StringMap = {
  PM: "PM",
  BL: "BL",
  MF: "MF",
  FFC: "FFC",
  SR: "SR",
  BS: "BS",
  LBX: "LBX",
  TV32: "TV",
  TV40: "TV",
  FT: "FT",
  DFT: "FT",
  HB: "HB",
  BB: "BB",
  DSK: "DSK",
  HBW: "HBW",
  HBL: "HBL",
};

export const getShortShort = (short: string): string => {
  const short_short = shortShortMap[short];

  if (!short_short) {
    throw new Error(`${short} couldnt be mapped to short_short`);
  }

  return short_short;
};

export type Count =
  | "bl_count"
  | "mf_count"
  | "ffc_count"
  | "sr_count"
  | "ft_count"
  | "dft_count"
  | "bs_count"
  | "lbx_count"
  | "pm_count"
  | "bb_count"
  | "dsk_count"
  | "tv32_count"
  | "tv40_count"
  | "hbl_count"
  | "hbw_count";

export const counts: Count[] = [
  "bl_count",
  "mf_count",
  "ffc_count",
  "sr_count",
  "ft_count",
  "dft_count",
  "bs_count",
  "bb_count",
  "lbx_count",
  "pm_count",
  "dsk_count",
  "tv32_count",
  "tv40_count",
  "hbw_count",
  "hbl_count",
];
